import Link from 'components/Link';
import './index.css';

const Footer = () => (
  <footer className="footer">
    <p className="footer__other-sites">
      <span>My portfolio iterations through the ages:</span>
    </p> 
    <p className="footer__other-sites">
      <Link secondary className="footer__link" href="http://rowleymacklin.com/diginuts-2004/" target="_self">2004</Link> | <Link secondary className="footer__link" href="http://rowleymacklin.com/diginuts-2013/" target="_self">2013</Link> | <Link secondary className="footer__link" href="http://rowleymacklin.com/diginuts-2016/" target="_self">2016</Link> | <Link secondary className="footer__link" href="http://rowleymacklin.com/fullershade-2018/" target="_self">2018</Link>
    </p> 
    <p className="footer__copyright">    
      <span className="footer__date">
        <Link secondary className="footer__link footer__link--last" href="/humans.txt" target="_self">
          {`${new Date().getFullYear()}`}
        </Link>
        Re-design by Rowley Macklin.
      </span>
    </p>
  </footer>
);

export default Footer;
