import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Intro from 'pages/Home/Intro';
import ProjectSummary from 'pages/Home/ProjectSummary';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import { useLocation } from 'react-router-dom';

import autosportTexturePlaceholder from 'assets/projects/work-autosport-piano-collage.jpg';
import autosportTextureLarge from 'assets/projects/work-autosport-home-light-large.jpg';

import autosportTextureMobilePlaceholder from 'assets/projects/work-autosport-home-mobile-light-placeholder.jpg';
import autosportTextureMobileLarge from 'assets/projects/work-autosport-home-mobile-light-large.jpg';
import autosportTextureMobile2Placeholder from 'assets/projects/work-autosport-home-mobile2-light-placeholder.jpg';
import autosportTextureMobile2Large from 'assets/projects/work-autosport-home-mobile2-light-large.jpg';

import mulberryTexturePlaceholder from 'assets/projects/work-mulberry-home-light-placeholder.jpg';
import mulberryTextureLarge from 'assets/projects/work-mulberry-home-light-large.jpg';

import avivaTexturePlaceholder from 'assets/projects/work-aviva-home-light-placeholder.jpg';
import avivaTextureLarge from 'assets/projects/work-aviva-home-light-large.jpg';

import avivaTextureMobilePlaceholder from 'assets/projects/work-aviva-home-mobile-light-placeholder.jpg';
import avivaTextureMobileLarge from 'assets/projects/work-aviva-home-mobile-light-large.jpg';
import avivaTextureMobile2Placeholder from 'assets/projects/work-aviva-home-mobile2-light-placeholder.jpg';
import avivaTextureMobile2Large from 'assets/projects/work-aviva-home-mobile2-light-large.jpg';

import oasisTexturePlaceholder from 'assets/projects/work-oasis-home-light-placeholder.jpg';
import oasisTextureLarge from 'assets/projects/work-oasis-home-light-large.jpg';

import tkmaxxTexturePlaceholder from 'assets/projects/work-tkmaxx-home-light-placeholder.jpg';
import tkmaxxTextureLarge from 'assets/projects/work-tkmaxx-home-light-large.jpg';

import tkmaxxTextureMobilePlaceholder from 'assets/projects/work-tkmaxx-home-mobile-light-placeholder.jpg';
import tkmaxxTextureMobileLarge from 'assets/projects/work-tkmaxx-home-mobile-light-large.jpg';
import tkmaxxTextureMobile2Placeholder from 'assets/projects/work-tkmaxx-home-mobile2-light-placeholder.jpg';
import tkmaxxTextureMobile2Large from 'assets/projects/work-tkmaxx-home-mobile2-light-large.jpg';

import gucciTexturePlaceholder from 'assets/projects/work-gucci-home-light-placeholder.jpg';
import gucciTextureLarge from 'assets/projects/work-gucci-home-light-large.jpg';

import goodyearTexturePlaceholder from 'assets/projects/work-goodyear-home-light-placeholder.jpg';
import goodyearTextureLarge from 'assets/projects/work-goodyear-home-light-large.jpg';

import goodyearTextureMobilePlaceholder from 'assets/projects/work-goodyear-home-mobile-light-placeholder.jpg';
import goodyearTextureMobileLarge from 'assets/projects/work-goodyear-home-mobile-light-large.jpg';
import goodyearTextureMobile2Placeholder from 'assets/projects/work-goodyear-home-mobile2-light-placeholder.jpg';
import goodyearTextureMobile2Large from 'assets/projects/work-goodyear-home-mobile2-light-large.jpg';

import sainsburysTexturePlaceholder from 'assets/projects/work-sainsburys-home-light-placeholder.jpg';
import sainsburysTextureLarge from 'assets/projects/work-sainsburys-home-light-large.jpg';

import barbourTexturePlaceholder from 'assets/projects/work-barbour-home-light-placeholder.jpg';
import barbourTextureLarge from 'assets/projects/work-barbour-home-light-large.jpg';

import drmartensTexturePlaceholder from 'assets/projects/work-drmartens-home-light-placeholder.jpg';
import drmartensTextureLarge from 'assets/projects/work-drmartens-home-light-large.jpg';

import drmartensTextureMobilePlaceholder from 'assets/projects/work-drmartens-home-mobile-light-placeholder.jpg';
import drmartensTextureMobileLarge from 'assets/projects/work-drmartens-home-mobile-light-large.jpg';
import drmartensTextureMobile2Placeholder from 'assets/projects/work-drmartens-home-mobile2-light-placeholder.jpg';
import drmartensTextureMobile2Large from 'assets/projects/work-drmartens-home-mobile2-light-large.jpg';

import variousTexturePlaceholder from 'assets/projects/work-various-home-light-placeholder.jpg';
import variousTextureLarge from 'assets/projects/work-various-home-light-large.jpg';

import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import './index.css';

const disciplines = ['Designer'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const Autosport = useRef(); 
  const Mulberry = useRef(); 
  const Aviva = useRef(); 
  const Oasis = useRef(); 
  const TKMaxx = useRef();  
  const Gucci = useRef(); 
  const Goodyear = useRef(); 
  const Sainsburys = useRef();   
  const Barbour = useRef(); 
  const DrMartens = useRef();  
  const Various = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [intro, Autosport, Mulberry, Aviva, Oasis, TKMaxx, Gucci, Goodyear, Sainsburys, Barbour, DrMartens, Various];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, Autosport, Mulberry, Aviva, Oasis, TKMaxx, Gucci, Goodyear, Sainsburys, Barbour, DrMartens, Various];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>Home | Rowley Macklin | Front end developer + Designer</title>
        <meta
          content="Portfolio of Rowley Macklin – a front end developer working on websites with a focus on making them function and look pretty."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />
      <ProjectSummary
        id="Autosport"
        sectionRef={Autosport}
        visible={visibleSections.includes(Autosport.current)}
        index={11}
        title="Autosport"
        titleDate="Mar 2019 - Dec 2020"
        titleRole="Front end developer"
        description="Setup and integrate new components to use Piano (Digital Business Platform)" 
        descriptionTwo=" • Convert main components to AMP (Accelerated Mobile Pages)" 
        descriptionThree=" • Re-style existing components and global navigation" 
        descriptionFour=" • Microsite: Code and style the annual Autosport Powerlist of 50 Most Influential People in Motorsport" 
        descriptionFive=" • Microsite: Code and style the annual Autosport Top 50 Drivers of 2019" 
        descriptionSix=" • Microsite: Code and style the Autosport Awards 2019 site" 
        descriptionSeven=" • Content and styling changes to F1Racing.com and the holding page for the new F1Racing site" 
        descriptionEight=" • Global bug fixing" 
        projectSummarySkills="PIANO (DIGITAL BUSINESS PLATFORM) • AMP (ACCELERATED MOBILE PAGES) • ANGULAR (VERY VERY BASICS) • PHP (VERY VERY BASICS) • TWIG • ZURB FOUNDATION • CSS/SASS • HTML • JQUERY • JAVASCRIPT • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • JSON/ AJAX" 
        buttonText="View Project"
        buttonLink="/projects/autosport"

        model={{
          type: 'phone',
          alt: 'Autosport work',
          textures: [
            {
              src: autosportTextureMobileLarge,
              placeholder: autosportTextureMobilePlaceholder,
            },
            {
              src: autosportTextureMobile2Large,
              placeholder: autosportTextureMobile2Placeholder,
            },
          ],
        }}

        /*model={{
          type: 'laptop',
          alt: 'Autosport front end developer',
          textures: [
            {
              src: autosportTextureLarge,
              placeholder: autosportTexturePlaceholder,
            },
          ],
        }}*/


      />
      <ProjectSummary
        id="Mulberry"
        sectionRef={Mulberry}
        visible={visibleSections.includes(Mulberry.current)}
        index={10}
        title="Mulberry"
        titleDate="Sept 2016 - Dec 2018"
        titleRole="Front end developer"
        description="Setup and integrate new Amplience Headless CMS Dynamic Content" 
        descriptionTwo=" • Microsite: Pre-production and live updates for the Eccentric Sensibility Korea fashion show" 
        descriptionThree=" • Styling global components & Localised fonts for China/ Korea" 
        descriptionFour=" • Overhaul the styling of the global site and bug fixing" 
        projectSummarySkills="AMPLIENCE (HEADLESS CONTENT AND COMMERCE) • HYBRIS • ZURB FOUNDATION • CSS/SASS • HTML • JQUERY • JAVASCRIPT • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • HANDLEBARS"         
        buttonText="View Project"
        buttonLink="/projects/mulberry"
        model={{
          type: 'laptop',
          alt: 'Mulberry work',
          textures: [
            {
              src: mulberryTextureLarge,
              placeholder: mulberryTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="Aviva"
        sectionRef={Aviva}
        visible={visibleSections.includes(Aviva.current)}
        index={9}
        title="Aviva Investors"
        titleDate="September 2016"
        titleRole="Front end developer"
        description="Microsite: Convert the desktop version of Chinese Economy to a responsive, mobile-first site"
        descriptionTwo=" • Daily global bug fixes and tweaks and regular content updates"     
        projectSummarySkills="CSS • HTML • JQUERY • RESPONSIVE DESIGN • MOBILE-FIRST DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • ILLUSTRATOR"         
        buttonText="View Project"
        buttonLink="/projects/aviva"

        model={{
          type: 'phone',
          alt: 'Aviva work',
          textures: [
            {
              src: avivaTextureMobileLarge,
              placeholder: avivaTextureMobilePlaceholder,
            },
            {
              src: avivaTextureMobile2Large,
              placeholder: avivaTextureMobile2Placeholder,
            },
          ],
        }}

        /*model={{
          type: 'laptop',
          alt: 'Aviva Investors front end developer',
          textures: [
            {
              src: avivaTextureLarge,
              placeholder: avivaTexturePlaceholder,
            },
          ],
        }}*/


      />
      <ProjectSummary
        id="Oasis"
        sectionRef={Oasis}
        visible={visibleSections.includes(Oasis.current)}
        index={8}
        title="Oasis Clothing"
        titleDate="August 2016"
        titleRole="Front end developer"
        description="Create responsive components & localised content within demandware"
        descriptionTwo=" • Bug fixing the current live site"    
        projectSummarySkills="DEMANDWARE • CSS • HTML • JQUERY • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP"         
        buttonText="View Project"
        buttonLink="/projects/oasis"
        model={{
          type: 'laptop',
          alt: 'Oasis work',
          textures: [
            {
              src: oasisTextureLarge,
              placeholder: oasisTexturePlaceholder,
            },
          ],
        }}
      />   
      <ProjectSummary
        id="TKMaxx"
        sectionRef={TKMaxx}
        visible={visibleSections.includes(TKMaxx.current)}
        index={7}
        title="TKMaxx"
        titleDate="Jan 2016 - Jul 2016"
        titleRole="Lead front end developer"
        description="Get new front end environment up and running within Hybris, using gulp and git"
        descriptionTwo=" • Co-ordinate front end team tasks and workload in Jira and daily scrums/ standups and write a front end best practices document"
        descriptionThree=" • Create new Hybris components"
        projectSummarySkills="HYBRIS • CSS/SASS • HTML • JQUERY • JAVASCRIPT • GULP • GIT • AGILE METHODOLOGY • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • ECLIPSE"         
        buttonText="View Project"
        buttonLink="/projects/tkmaxx"

        model={{
          type: 'phone',
          alt: 'TKMaxx work',
          textures: [
            {
              src: tkmaxxTextureMobileLarge,
              placeholder: tkmaxxTextureMobilePlaceholder,
            },
            {
              src: tkmaxxTextureMobile2Large,
              placeholder: tkmaxxTextureMobile2Placeholder,
            },
          ],
        }}

        /*model={{
          type: 'laptop',
          alt: 'TKMaxx lead front end developer',
          textures: [
            {
              src: tkmaxxTextureLarge,
              placeholder: tkmaxxTexturePlaceholder,
            },
          ],
        }}*/


      />    
      <ProjectSummary
        id="Gucci"
        sectionRef={Gucci}
        visible={visibleSections.includes(Gucci.current)}
        index={6}
        title="Gucci"
        titleDate="Aug 2014 - Dec 2015"
        titleRole="Lead front end developer"
        description="Create new Hybris components"
        descriptionTwo=" • Review the static code base (provided by a design agency), clean it up and merge it into Gucci’s new Hybris system"
        descriptionThree=" • Co-ordinate front end team tasks and workload in Jira and daily scrums/ standups"
        descriptionFour=" • Bug fixing the current live site"  
        projectSummarySkills="HYBRIS • CSS/SASS • HTML • JQUERY • JAVASCRIPT • GRUNT • GIT • AGILE METHODOLOGY • BOOTSTRAP • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • ECLIPSE"                       
        buttonText="View Project"
        buttonLink="/projects/gucci"
        model={{
          type: 'laptop',
          alt: 'Gucci work',
          textures: [
            {
              src: gucciTextureLarge,
              placeholder: gucciTexturePlaceholder,
            },
          ],
        }}
      />  
      <ProjectSummary
        id="Goodyear"
        sectionRef={Goodyear}
        visible={visibleSections.includes(Goodyear.current)}
        index={5}
        title="Goodyear"
        titleDate="Feb 2014 - Aug 2014"
        titleRole="Front end developer"
        description="Create new Hybris components"
        descriptionTwo=" • Review the static code base (provided by a design agency based in Costa Rica), clean it up and merge it into Goodyear’s new Hybris system"
        descriptionThree=" • Assist the on-site Boston team with Hybris integration"
        descriptionFour=" • Bug fixing the current live site" 
        projectSummarySkills="HYBRIS • CSS/SASS • HTML • JQUERY • JAVASCRIPT • GRUNT • GIT • AGILE METHODOLOGY • BOOTSTRAP • RESPONSIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • ECLIPSE • HANDLEBARS"                       
        buttonText="View Project"
        buttonLink="/projects/goodyear"

        model={{
          type: 'phone',
          alt: 'Goodyear work',
          textures: [
            {
              src: goodyearTextureMobileLarge,
              placeholder: goodyearTextureMobilePlaceholder,
            },
            {
              src: goodyearTextureMobile2Large,
              placeholder: goodyearTextureMobile2Placeholder,
            },
          ],
        }}


        /*model={{
          type: 'laptop',
          alt: 'Goodyear front end developer',
          textures: [
            {
              src: goodyearTextureLarge,
              placeholder: goodyearTexturePlaceholder,
            },
          ],
        }}*/


      />      
      <ProjectSummary
        id="Sainsburys"
        sectionRef={Sainsburys}
        visible={visibleSections.includes(Sainsburys.current)}
        index={4}
        title="Sainsbury's"
        titleDate="Dec 2013 - Feb 2014"
        titleRole="Front end developer"
        description="Create and style new Hybris forms & hero components"
        descriptionTwo=" • Bug fixing the current live site"  
        projectSummarySkills="HYBRIS • CSS • HTML • JQUERY • JAVASCRIPT • SVN • AGILE METHODOLOGY • ADAPTIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP"                       
        buttonText="View Project"
        buttonLink="/projects/sainsburys"
        model={{
          type: 'laptop',
          alt: 'Sainsbury’s work',
          textures: [
            {
              src: sainsburysTextureLarge,
              placeholder: sainsburysTexturePlaceholder,
            },
          ],
        }}
      /> 
      <ProjectSummary
        id="Barbour"
        sectionRef={Barbour}
        visible={visibleSections.includes(Barbour.current)}
        index={3}
        title="Barbour"
        titleDate="Nov 2013 - Dec 2013"
        titleRole="Front end developer"
        description="Pre-production bug fixing (Last 2 sprints)"      
        projectSummarySkills="HYBRIS • CSS • HTML • JQUERY • JAVASCRIPT • SVN • AGILE METHODOLOGY • ADAPTIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP"                       
        buttonText="View Project"
        buttonLink="/projects/barbour"
        model={{
          type: 'laptop',
          alt: 'Barbour work',
          textures: [
            {
              src: barbourTextureLarge,
              placeholder: barbourTexturePlaceholder,
            },
          ],
        }}
      />   
      <ProjectSummary
        id="DrMartens"
        sectionRef={DrMartens}
        visible={visibleSections.includes(DrMartens.current)}
        index={2}
        title="Dr Marten's"
        titleDate="Jan 2013 - Nov 2013"
        titleRole="Designer | Lead front end developer"
        description="Create new designs based on wireframes"
        descriptionTwo=" • Create semantic code based on the designs within Hybris & bug fixing"    
        projectSummarySkills="HYBRIS • CSS • HTML • JQUERY • JAVASCRIPT • SVN • AGILE METHODOLOGY • ADAPTIVE DESIGN • BUG FIXING • SEMANTIC MARKUP • SUBLIME TEXT • PHOTOSHOP • ILLUSTRATOR"                       
        buttonText="View Project"
        buttonLink="/projects/drmartens"

        model={{
          type: 'phone',
          alt: 'Dr Marten’s work',
          textures: [
            {
              src: drmartensTextureMobileLarge,
              placeholder: drmartensTextureMobilePlaceholder,
            },
            {
              src: drmartensTextureMobile2Large,
              placeholder: drmartensTextureMobile2Placeholder,
            },
          ],
        }}

        /*model={{
          type: 'laptop',
          alt: 'Dr Marten’s designer/ lead front end developer',
          textures: [
            {
              src: drmartensTextureLarge,
              placeholder: drmartensTexturePlaceholder,
            },
          ],
        }}*/

      />   
      <ProjectSummary
        id="Various"
        sectionRef={Various}
        visible={visibleSections.includes(Various.current)}
        index={1}
        title="Various"
        titleDate="Renaissance - Nov 2012"
        titleRole="Web designer | Web analyst | Front end developer"
        description="Various web design, web analyst & front end work"    
        descriptionTwo=" • Various print & logo work"   
        buttonText="View Project"
        buttonLink="/projects/various"
        model={{
          type: 'laptop',
          alt: 'Various work',
          textures: [
            {
              src: variousTextureLarge,
              placeholder: variousTexturePlaceholder,
            },
          ],
        }}
      />                                
      <Footer />
    </div>
  );
};

export default Home;